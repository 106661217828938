import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

const Analytics: FC = () => (
  <Helmet>
    {/*  Global site tag (gtag.js) - Google Analytics  */}
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-213705367-1" />
    <script>
      {`
        window.dataLayer = window.dataLayer || [];  function gtag(){dataLayer.push(arguments);}  gtag('js', new Date());
        gtag('config', 'UA-213705367-1');
      `}
    </script>
    {/*  Google Tag Manager  */}
    <script>
      {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-P9ZSWXW');
      `}
    </script>
    <noscript>
      {`<iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-P9ZSWXW"
        height="0"
        width="0"
        style="display:none;visibility:hidden"></iframe>`}
    </noscript>

    {/* Yandex.Metrika counter */}
    <script type="text/javascript">
      {`
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
        m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

        ym(86671134, "init", {
             clickmap:true,
             trackLinks:true,
             accurateTrackBounce:true,
             webvisor:true
        });
      `}
    </script>
    <noscript>
      {`<div>
        <img src="https://mc.yandex.ru/watch/86671134" style="position:absolute; left:-9999px;" alt="" />
      </div>`}
    </noscript>
    {/* End Yandex.Metrika counter */}

    {/* Facebook Pixel Code */}
    <script type="text/javascript">
      {`
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1904251343168406');
        fbq('track', 'PageView');
      `}
    </script>
    <noscript>
      {`
      <img
        height="1"
        width="1"
        style={{ display: 'none' }}
        src="https://www.facebook.com/tr?id=1904251343168406&ev=PageView&noscript=1"
        alt=""
      />
      `}
    </noscript>
    {/* End Facebook Pixel Code */}
    <script>
      {`window.fbAsyncInit = function() {
        FB.init({
          appId: '271953021555662',
          autoLogAppEvents: true,
          xfbml: true,
          version: 'v12.0'
        });
      };`}
    </script>
    <script async defer crossOrigin="anonymous" src="https://connect.facebook.net/en_US/sdk.js" />

  </Helmet>
);

export default Analytics;
