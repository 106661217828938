import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';

import { IMetaPreview, IQueryPage } from 'interfaces/page';
import { IMediaImage } from 'interfaces/media';

const query = graphql`
  query {
    page: strapiHolograktorPage {
      meta {
        ...StrapiMetaFragment
      }
      widgets {
        widget_name
        title
        id
        header
        text {
          id
          text
        }
      }
    }
  }
`;

interface IHead {
  title?: string;
  description?: string;
  preview?: IMetaPreview | IMediaImage | string;
  url?: string;
}

const websiteUrl = 'https://holograktor.com';

const Head: FC<IHead> = ({
  title, description, preview, url = websiteUrl,
}) => (
  <StaticQuery
    query={query}
    render={(data: IQueryPage) => {
      const siteInfo = data.page.meta;

      const large = typeof preview === 'string'
        ? preview
        : (preview?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src
          || siteInfo?.preview?.localFile?.childImageSharp.gatsbyImageData.images.fallback.src);
      const pageTitle = `Holograktor ${title?.length ? ` | ${title}` : ''}`;

      const pageDescription = description && description.length
        ? description
        : siteInfo.description;

      return (
        <Helmet
          htmlAttributes={{
            lang: 'en',
          }}
          title={pageTitle}
        >
          <title>{pageTitle}</title>

          <meta
            name="description"
            content={pageDescription}
          />

          <meta name="twitter:image" content={`${websiteUrl}${large}`} />
          <meta property="og:type" content="website" />
          <meta property="og:url" content={url} />
          <meta property="og:site_name" content={pageTitle} />
          <meta
            property="og:image"
            itemProp="image primaryImageOfPage"
            content={`${websiteUrl}${large}`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:domain" content={websiteUrl} />
          <meta name="twitter:title" property="og:title" itemProp="name" content={pageTitle} />
          <meta name="twitter:description" property="og:description" itemProp="description" content={pageDescription} />

          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          />
          <meta name="facebook-domain-verification" content="uv26ehvrfvhkhmzcah9s6onzyknitn" />
        </Helmet>
      );
    }}
  />
);

export default Head;
