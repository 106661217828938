import React, { FC, ReactNode } from 'react';
import { Global } from '@emotion/react';
import { Provider } from 'react-redux';

import { store } from 'store';

import { GlobalStyles } from './Layout.styled';
import Analytics from './Head/Analytics';
import Head from './Head/Head';

import './fragments/widgets';
import './fragments/pages';
import './fragments/components';

import './fonts.css';
import './variables.css';
import './common.css';

interface ILayout {
  children: ReactNode;
  location: ILocation;
  path: string;
}

const Layout: FC<ILayout> = ({
  children,
}) => (
  <Provider store={store}>
    <Head />
    <Global styles={GlobalStyles} />
    <Analytics />

    {children}
  </Provider>
);

export default Layout;
