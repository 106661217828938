const initialState: BlockState = {
  activeBlock: '',
  previousBlock: '',
  cookieAgreement: false,
  windowSize: 1280,
  isMobile: true,
  isTablet: false,
};

export default initialState;
