import { css } from '@emotion/react';
import styled from '@emotion/styled';

import './variables.css';

import {
  MAX_MOB_WIDTH, MIN_2K, MIN_4K, MAX_TABLET_WIDTH,
} from 'constants/sizes';

export const PageWrapper = styled.div`
  grid-column: 2;
  background-color: var(--athens-gray);
  display: grid;
  grid-auto-flow: row;
`;

export const Container = styled.div`
  display: grid;
  min-height: 100vh;
  grid-auto-flow: row;
`;

export const GlobalStyles = css`
  html, * {
    font-size: 12px;
    line-height: 1.3;

    @media (min-width: 1280px) {
      font-size: 0.9375vw;
    }

    @media (max-width: 767px) {
      font-size: 3.467vw;
    }
  }

  body {
    font-family: 'Way Ray Grotesk', sans-serif;
    padding: 0;
    margin: 0;
    overscroll-behavior-y: none;
  }

  *:after,
  *:before {
    font-family: 'Way Ray Grotesk', sans-serif;
  }

  h1 {
    font-size: 10.44rem;
    line-height: 0.85;
    letter-spacing: -0.02em;
    margin: 0;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 3rem;
    }
  }

  h2 {
    font-size: 4.5rem;
    line-height: 1.1;
    letter-spacing: -0.02em;

    @media (max-width: ${MAX_TABLET_WIDTH}px) {
    }

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 3rem;
      line-height: 1.05;
      letter-spacing: -0.01em;
    }
  }

  h3 {
    font-size: 2.85rem;
    line-height: 1.1;
    letter-spacing: -0.01em;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      font-size: 28px;
      line-height: 32px;
    }
  }

  h4 {
    font-size: 1.8em;
    line-height: 1.4em;
  }

  p {
    letter-spacing: 0.01em;

    @media (max-width: ${MAX_MOB_WIDTH}px) {
      letter-spacing: 0.02em;
    }

    @media (min-width: ${MIN_2K}px) {
      letter-spacing: 0em;
    }

    @media (min-width: ${MIN_4K}px) {
      letter-spacing: -0.02em;
    }
  }

  u {
    text-decoration: none;
    white-space: nowrap;
  }

  em {
    font-style: italic;
  }

  strong {
    font-weight: 600;
  }

  small {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0em;

    @media (min-width: ${MIN_2K}px) {
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.01em;
    }

    @media (min-width: ${MIN_4K}px) {
      font-size: 32px;
      line-height: 40px;
    }
  }

  body.stop-scrolling {
    height: 100%;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }

  svg {
    display: block;
  }

  button {
    border: none;
    background: none;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
