export const STANDART_DESKTOP_WIDTH = 1440;
export const MAX_TABLET_WIDE_WIDTH = 1439;
export const MAX_TABLET_MEDIUM_WIDTH = 1365;
export const MAX_TABLET_WIDTH = 1279;
export const MIN_TABLET_WIDTH = 768;
export const MAX_MOB_WIDTH = 767;
export const MIN_MOB_WIDTH = 320;
export const SLIDES_QUANTITY = 30;

export const mobWidth = `${MAX_MOB_WIDTH}px`;
export const tabletWidth = `${MAX_TABLET_WIDTH}px`;
export const desktopWidth = `${MAX_TABLET_WIDTH}px`;

export const MOBILE_TOP_MENU_HEIGHT = 52;

export const MIN_2K = 2160;
export const MIN_4K = 3840;
